/* eslint-disable no-case-declarations */
import React, { FC, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { log, toast } from '../../utils/notification';
import { withRouterHooks } from '../../routes/router.hooks';
import LoginTokenDialog from './tokenDialog';
import { FormValidator, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import getAuthenticationMode from '../../api/query/user/getAuthenticationMode';
import { isCookieValid } from '../../utils/cookies';
import logo from '../../images/logo.png';
import packageJson from '../../../package.json';
import './styles.css';
import request from '../../request/request';

const LoginPage: FC<any> = () => {
  const userNameRef = useRef(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // ToDo: clarify usage
  // const [path, setPath] = useState('#');
  const [dialogVisible, setDialogVisible] = useState(false);
  const [authenticationMode, setAuthenticationMode] = useState(null);
  const [isDBConnected, setIsDBConnected] = useState(true);

  const version = `v${packageJson.version}`;
  // const stage = process.env.NODE_ENV !== 'production' ? ` on stage ${process.env.NODE_ENV}` : '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [theme, setTheme] = useState<any>(null);

  const expired = JSON.parse(localStorage.getItem('expired'));
  const logout = JSON.parse(localStorage.getItem('logout'));


  useEffect(() => {

    userNameRef.current.focusIn();
    const options = {
      rules: {
        username: {
          required: [true, '* Please enter your name'],
        },
        password: {
          required: [true, '* Please enter your password'],
        },
      },
    };
    new FormValidator('#form', options);
    
    const checkSession = async () => {
      const { valid } = await request(false, 'GET', 'public/check-session', {});
      if(valid){
        toast.warning('Your are logged in');
        return  window.location.href = '/app';
      } 
    };

    const checkDBConnection = async () => {
      const response = await request<any, any>(false, 'POST', 'public/check-db-connection', {});
      if (!response.error) {
        const mode = await getAuthenticationMode();
        setAuthenticationMode(mode);
      } else {
        setIsDBConnected(false);
        toast.error('', response.message);
      }
    };

    const getTheme = async () => {
      const response = await request<any, any>(false, 'GET', 'public/services/assets/logo');
      const logoData = response.data.response;
      setTheme(logoData);
    };


    try {
      checkSession();
      checkDBConnection();
      getTheme();
    } catch (error) {
      log('Request', { response: error.message }, 'Error');
    }


    if (expired && !logout) {
      toast.warning('session expired');
    }
    localStorage.removeItem('expired');
    localStorage.removeItem('logout');

    
    return () => {
      setIsDBConnected(true);
    };

  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      switch (authenticationMode) {
        case 'password-only':
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const response = await request<Request.Login, any>(false, 'POST', 'public/auth/signin', { authenticationMode: authenticationMode, username, password, version, token: '' });
          Object.keys(response.data).forEach(key => localStorage.setItem(key, JSON.stringify(response.data[key])));
          resetState(e);
          window.location.href = '/app';
          break;
        case 'two-factor':
          if (isCookieValid('sessionCookie')) {
            const response = await request<Request.Login, any>(false, 'POST', 'public/auth/signin', { authenticationMode: 'password-only', username, password, version, token: '' });
            Object.keys(response.data).forEach(key => localStorage.setItem(key, JSON.stringify(response.data[key])));
            resetState(e);
            window.location.href = '/app';
            break;
          } else {
            const response = await request<Request.Login, any>(false, 'POST', 'public/auth/signin', { authenticationMode: 'password-only', username, password, version, token: '' });
            if (response.data) {
              setDialogVisible(true);
            }
          }
          break;
        default:
          return null;
      }
    } catch (error) {
      if (error.response) {
        await request(true, 'POST', 'public/user/logout' );
        toast.error('', error.response.data.message);
      }
    }
  };


  const resetState = (e) => {
    e.preventDefault();
    setUsername('');
    setPassword('');
  };


  // const handelLinkClick = () => {
  //   if (username === '') {
  //     return toast.error('To reset your password please enter your user name');
  //   } else {
  //     history('/request-password');
  //   }
  //   return;
  // };

  return (
    <>
      <div className='login-container'>
        <div className='login-logo'>
          {theme && (
            <img src={theme.logo ?? logo} alt="Logo" style={{ maxWidth: '250px' }} />
          )}
        </div>
        <h1 className="login-form-title" >Login</h1>
        <div className={isDBConnected ? 'login-form-container' : 'login-form-container-deactivated'}>
          <form
            id="form"
            className='login-form'
          >
            <TextBoxComponent
              ref={userNameRef}
              name="username"
              className='login-form-input'
              value={username}
              change={(e) => {
                // if (e.value.length !== 0) {
                //   setPath('/request-password');
                // } else {
                //   setPath('#');
                // }
                setUsername(e.value);
                localStorage.setItem('user', e.value);
              }}
              placeholder="Name"
              floatLabelType="Always"
              data-msg-containerid="error-username"
            />
            <div id="error-username" />
            <TextBoxComponent
              type="password"
              name="password"
              className='login-form-input'
              value={password}
              change={(e) => { setPassword(e.value); }}
              placeholder="Password"
              floatLabelType="Always"
              data-msg-containerid="error-password"
            />
            <div id="error-password" />
            <div className="login-buttons">
              <ButtonComponent
                cssClass='e-custom'
                style={{ textTransform: 'none' }}
                content={'Login'}
                onClick={(e) => handleSubmit(e)}
              />
              <ButtonComponent
                cssClass='e-custom'
                style={{ textTransform: 'none' }}
                content={'Reset'}
                onClick={(e) => resetState(e)}
              />
            </div>
            <LoginTokenDialog
              dialogVisible={dialogVisible}
              username={username}
              password={password}
              resetState={resetState}
              setDialogVisible={setDialogVisible}
              version={version}
              authenticationMode={authenticationMode}
            />
          </form>
        </div>
        <div className={isDBConnected ? 'login-footer' : 'login-footer-deactivated'}>
          {/* <Link to={path}
            onClick={() => handelLinkClick()}
          >Forgot your password?</Link> */}
          <footer>
            <div className="version-tag">powered by graph-designer {version}</div>
          </footer>
        </div>
      </div >
      <ToastContainer />
    </>
  );
};

export default withRouterHooks(LoginPage);