import { NodeSelection } from '@syncfusion/ej2-react-richtexteditor';
import { DropDownButton } from '@syncfusion/ej2-react-splitbuttons';
import { openRichtextDialog } from '../../../../../store/workspaces/modals/actions';
import store from '../../../../../store/store';

let saveSelection: NodeSelection;


export const setDropdownButtonToolbar = async (instance: any, setTextSelection: any, params: any, field: any, setDisableLinkClass: any) => {

  const setImage = (element: any, externURL: any) => {

    if (!saveSelection) return null;

    if (field.type === 'markdown') {
      saveSelection.restore();
      instance.executeCommand('insertText', `![](${externURL ? externURL : element.image})`);
      instance.formatter.saveData();
      instance.formatter.enableUndo(instance);
      return null;
    }

    saveSelection.restore();
    instance.executeCommand('insertHTML', `<img src="${externURL ? externURL : element.image}}"/>`);
    instance.formatter.saveData();
    instance.formatter.enableUndo(instance);
  };

  const setLink = (element: any, inputValue: any, externURL: any) => {

    if (inputValue === '' || inputValue === null || inputValue === undefined || !saveSelection) return null;

    if (field.type === 'markdown') {
      saveSelection.restore();
      if (element && element?.uuid) {
        instance.executeCommand('insertText', `[${inputValue}](${element.uuid})`);
      } else if (externURL) {
        instance.executeCommand('insertText', `[${inputValue}](${externURL})`);
      } else {
        return null;
      }
      instance.formatter.saveData();
      instance.formatter.enableUndo(instance);

      return null;
    }

    saveSelection.restore();
    if (element && element?.uuid) {
      instance.executeCommand('insertHTML', `<a href="${element.uuid}">${inputValue}<a/>`);
    } else if (externURL) {
      instance.executeCommand('insertHTML', `<a href="${externURL}">${inputValue}<a/>`);
    } else {
      return null;
    }
    instance.formatter.saveData();
    instance.formatter.enableUndo(instance);

    setDisableLinkClass(true);
  };

  const setDiagram = (element: any) => {

    if (field.type === 'markdown') {
      saveSelection.restore();
      instance.executeCommand('insertText', `![](${element.preview})`);
      instance.formatter.saveData();
      instance.formatter.enableUndo(instance);
      return null;
    }

    saveSelection.restore();
    instance.executeCommand('insertHTML', `<img src="${element.preview}"/>`);
    instance.formatter.saveData();
    instance.formatter.enableUndo(instance);
  };

  const handleSelectedElement = () => {
    const selection = window.getSelection();
    const aTag: any = selection.anchorNode.parentElement;
    setTextSelection({
      text: aTag.textContent,
      href: aTag.href
    });
    return null;
  };

  const db_extern: DropDownButton = new DropDownButton({
    beforeOpen: () => {
      handleSelectedElement();
      const selection: NodeSelection = new NodeSelection();
      const range = selection.getRange(document);
      saveSelection = selection.save(range, document);
    },
    items: dropdownFields_extern,
    content: 'extern',
    select: (args: any) => {
      switch (args.item.properties.text) {
        case 'link':
          (instance.contentModule.getEditPanel() as HTMLElement).focus();
          return store.dispatch(openRichtextDialog(
            {
              ...params,
              type: 'zoom',
              selectedText: window.getSelection().toString()
            },
            setImage,
            setLink,
            setDiagram,
            'link'
          ));
        case 'image':
          (instance.contentModule.getEditPanel() as HTMLElement).focus();
          return store.dispatch(openRichtextDialog(
            {
              ...params,
              type: 'zoom',
              selectedText: window.getSelection().toString()
            },
            setImage,
            setLink,
            setDiagram,
            'image'
          ));
        default:
          return null;
      }

    }
  });



  const db_intern: DropDownButton = new DropDownButton({
    beforeOpen: () => {
      handleSelectedElement();
      const selection: NodeSelection = new NodeSelection();
      const range = selection.getRange(document);
      saveSelection = selection.save(range, document);
    },
    items: dropdownFields_intern,
    content: 'intern',
    select: (args: any) => {
      switch (args.item.properties.text) {
        case 'link':
          (instance.contentModule.getEditPanel() as HTMLElement).focus();
          return store.dispatch(openRichtextDialog(
            {
              ...params,
              type: 'zoom',
              selectedText: window.getSelection().toString()
            },
            setImage,
            setLink,
            setDiagram,
            'link_grid'
          ));
        case 'image':
          (instance.contentModule.getEditPanel() as HTMLElement).focus();
          return store.dispatch(openRichtextDialog(
            {
              ...params,
              type: 'zoom',
              selectedText: window.getSelection().toString()
            },
            setImage,
            setLink,
            setDiagram,
            'image_grid'
          ));
        case 'diagram':
          (instance.contentModule.getEditPanel() as HTMLElement).focus();
          return store.dispatch(openRichtextDialog(
            {
              ...params,
              type: 'zoom',
              selectedText: window.getSelection().toString()
            },
            setImage,
            setLink,
            setDiagram,
            'diagram_grid'
          ));
        default:
          return null;
      }

    }
  });
  return {
    dropDownButtonExtern: db_extern,
    dropDownButtonIntern: db_intern
  };
};


const dropdownFields_intern: any = [
  { iconCss: 'e-icons e-link-2', text: 'link', id: 'custom_link' },
  { iconCss: 'e-icons e-image', text: 'image', id: 'custom_image' },
  { iconCss: 'e-icons e-transform', text: 'diagram', id: 'custom_diagram' },
];

const dropdownFields_extern: any = [
  { iconCss: 'e-icons e-link-2', text: 'link', id: '1' },
  { iconCss: 'e-icons e-image', text: 'image', id: '2' }
];



