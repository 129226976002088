import React, { useEffect, useState, useRef } from 'react';
import MenuBar from '../../components/MenuBar/MenuBar';
import CustomForm from './Form.list';
import { useTranslation } from 'react-i18next';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import { WidgetProvider } from '../WidgetContext';
import PropTypes from 'prop-types';
import { WidgetLabelStyled } from '../styles';
import { FormWidgetContainerStyled, GridStyled } from './styles';
import { toast } from '../../utils/notification';

const FormWidget = ({ navData, actionsState }) => {
  const [formData, setFormData] = useState(null);
  const [formEditData, setFormEditData] = useState([]);
  const [formEditFiles, setFormEditFiles] = useState(null);
  const [formOnSubmitFunc, setFormOnSubmitFunc] = useState(null);
  const { t } = useTranslation();

  const validationFormObjectRef = useRef(null);

  const widgetContext = {
    formEditData: formEditData,
    formEditFiles: formEditFiles,
    widgetData: navData.widgetData,
    actionsState: actionsState,
    validationFormObject: validationFormObjectRef.current
  };

  const fetchFormData = async () => {
    try {
      const newParams = { ...navData.params };
      newParams.source = { ...newParams.source, id: navData.widgetData.dataID };

      const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.widgetData.key }, navData.widgetData);

      initFormEditData();

      const newEditData = [];
      Object.keys(response.targets[0]).forEach(function (key) {
        navData.widgetData.sections.forEach(function (s) {
          s.fieldSets.forEach(function (fs) {
            fs.fields.forEach(function (field) {
              if (field.attribute.name === key) {
                const o = { _key: field.attribute.key ?? null };
                o[key] = response.targets[0][key] ?? null;
                newEditData.push(o);
              }
            });
          });
        });
      });

      setFormData(response.targets[0]);
      setFormEditData(newEditData);
    } catch (error) {
      setFormData(null);
      toast.error('error fetch form data:', error.message);
    }
  };

  useEffect(() => {
    fetchFormData();
    return () => {
      setFormData(null);
      setFormEditData(null);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);

  const initFormEditData = () => {
    setFormEditData([]);
    setFormEditFiles(null);
    setFormOnSubmitFunc(null);
  };

  const handleOnSubmit = async () => {
    if (formOnSubmitFunc) {
      formOnSubmitFunc();
    }
  };

  const _setFormEditData = (item) => {
    if (item.id) return null;

    setFormEditData((prevFormEditData) => {
      const updatedData = [];
      Array.from(prevFormEditData).forEach(
        (data) => {
          updatedData.push(data);
        }
      );
      const index = updatedData.findIndex(x => x._key === item._key);

      if (index === -1) {
        updatedData.push(item);
      } else {
        updatedData[index] = item;
      }
      
      return updatedData;
    });
  };

  if (!formData) {
    return null;
  }

  return (
    <WidgetProvider value={widgetContext}>
      <form id={`form-${navData.widgetData.key}`} onSubmit={handleOnSubmit}>
        {navData.widgetData.menu ? (
          <MenuBar
            menu={navData.widgetData.menu}
          />
        ) : null}
        {navData.widgetData.label !== '' ? <WidgetLabelStyled>{t(navData.widgetData.label)} </WidgetLabelStyled> : ''}
        <FormWidgetContainerStyled>
          <GridStyled divided="vertically">
            <CustomForm
              renderData={navData.widgetData}
              data={formData}
              params={{ key: navData.widgetData.key, id: navData.widgetData.dataID }}
              editData={formEditData}
              setFormEditData={_setFormEditData}
              setFormEditFile={setFormEditFiles}
              viewType={navData.widgetData.type}
            />
          </GridStyled>
        </FormWidgetContainerStyled>
      </form>
    </WidgetProvider>
  );
};

FormWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default FormWidget;
