import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table, Resize, MarkdownEditor, MarkdownFormatter, PasteCleanup, PasteCleanupSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import { useTranslation } from 'react-i18next';
import { setTextSelection } from '../../../../store/workspaces/modals/actions';
import { marked } from 'marked';
import { createElement, isNullOrUndefined } from '@syncfusion/ej2-base';
import { getToolbarSetting } from './toolbar/toolbar.settings';
import { setDropdownButtonToolbar } from './toolbar/Toolbar.dropdown';
import { run as runActions } from '../../../../store/workspaces/workspaces/run';
import { service as linkService } from '../../../View/text/service/linkService';
import PropTypes from 'prop-types';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from '../styles';

const RichTextField = ({ viewType, field, value, params, renderData, setNewValue }) => {
  const rteObjRef = useRef(null);
  const { t } = useTranslation();

  const pasteCleanupSettings: PasteCleanupSettingsModel  = {
    // prompt: true,
    deniedAttrs: ['contenteditable', 'disabled', 'readonly', 'id', 'style', 'class']
  };

  const setDisableLinkClass = (inactive) => {
    const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const eventKey = renderData.events?.find((event) => event?.type === 'onClick')?.key;

    document.querySelectorAll('a').forEach((element) => {
      if (element.href) {
        const url = new URL(element.href);
        const uuid = url.pathname.slice(1);

        if (regexExpUUID.test(uuid) || url.href) {
          element.classList.toggle('disable-link', inactive);

          if (!inactive) {
            element.addEventListener('click', (event) => {
              event.preventDefault();
              const url = new URL(element.href);
              const uuid = url.pathname.slice(1);

              if (regexExpUUID.test(uuid)) {
                event.preventDefault();
                const params = { uuid: uuid };

                linkService('system', params)
                  .then((response) => runActions(eventKey, response.id, null))
                  .catch((error) => console.log(error));
              } else {
                window.open(url.href, '_blank');
              }
            });
          }
        }
      }
    });
  };

  const markdownConversion = () => {
    if (rteObjRef.current.editorMode === 'Markdown' && rteObjRef.current.contentModule) {
      const htmlPreview = rteObjRef.current.element.querySelector('.e-pre-source');
      if (htmlPreview) {
        htmlPreview.innerHTML = marked(rteObjRef.current.value);
      }
    }
  };

  const fullPreviewMarkdown = (e) => {
    const id = rteObjRef.current.getID() + 'html-preview';
    const htmlPreview = rteObjRef.current.element.querySelector('#' + id);
    if (
      rteObjRef.current.editorMode === 'Markdown' &&
      rteObjRef.current.element.querySelector('#preview-code')
    ) {
      const mdsource = rteObjRef.current.element.querySelector('#preview-code');
      if (mdsource.classList.contains('e-active') && e.mode) {
        mdsource.classList.remove('e-active');
        mdsource.parentElement.title = 'Preview';
        rteObjRef.current.contentModule.getEditPanel().style.display = 'block';
        rteObjRef.current.contentModule.getEditPanel().style.width = '100%';
        if (htmlPreview) {
          htmlPreview.style.display = 'none';
        }
      } else {
        mdsource.classList.add('e-active');
        if (!htmlPreview) {
          const div = createElement('div', { className: 'e-content' });
          div.id = id;
          rteObjRef.current.contentModule.getPanel().appendChild(div);
        }
        if (e.type === 'preview') {
          rteObjRef.current.contentModule.getEditPanel().style.display = 'none';
          htmlPreview.classList.add('e-pre-source');
        } else {
          htmlPreview.classList.remove('e-pre-source');
          rteObjRef.current.contentModule.getEditPanel().style.width = '50%';
        }
        if (htmlPreview) {
          htmlPreview.style.display = 'block';
          htmlPreview.innerHTML = marked(rteObjRef.current.value);
        }
        mdsource.parentElement.title = 'Code View';
      }
    }
  };

  const actionComplete = (e) => {
    const mdsource = rteObjRef.current.element.querySelector('#preview-code');
    if (rteObjRef.current.editorMode === 'Markdown' && mdsource) {
      if (e.targetItem === 'Maximize' && isNullOrUndefined(e.args)) {
        fullPreviewMarkdown({ mode: true, type: '' });
      } else if (!mdsource.parentElement.classList.contains('e-overlay')) {
        if (e.targetItem === 'Minimize') {
          rteObjRef.current.contentModule.getEditPanel().style.display = 'block';
          rteObjRef.current.contentModule.getEditPanel().style.width = '100%';
          const htmlPreview = rteObjRef.current.element.querySelector('.e-pre-source');
          if (htmlPreview) {
            htmlPreview.style.display = 'none';
          }
          mdsource.classList.remove('e-active');
          mdsource.parentElement.nextElementSibling?.classList.remove('e-active');
        }
        markdownConversion();
      }
      rteObjRef.current.toolbarModule.refreshToolbarOverflow();
    }
  };

  const getChanges = (args) => setNewValue(args.value);

  const onCreate = () => {
    if (field.type === 'markdown') {
      const textArea = rteObjRef.current.contentModule.getEditPanel() as HTMLTextAreaElement;
      textArea.addEventListener('keyup', markdownConversion);
      const rteObj = rteObjRef.current;
      const mdsource = document.getElementById('preview-code');

      mdsource?.addEventListener('click', (e) => {
        fullPreviewMarkdown({ mode: true, type: 'preview' });
        const target = e.currentTarget as HTMLElement;
        if (target.classList.contains('e-active')) {
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(18, false);
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(19, false);
          rteObj.disableToolbarItem([
            'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent',
            'Indent', 'ClearFormat', 'Print', 'SourceCode', 'FullScreen', 'CreateTable', '|', 'Undo', 'Redo',
          ]);
          target.parentElement?.nextElementSibling?.classList.add('e-overlay');
        } else {
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(18, true);
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(19, true);
          rteObj.enableToolbarItem([
            'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent',
            'Indent', 'ClearFormat', 'Print', 'SourceCode', 'FullScreen', 'CreateTable', '|', 'Undo', 'Redo',
          ]);
          target.parentElement?.nextElementSibling?.classList.remove('e-overlay');
        }
      });

      const mdSplit = document.getElementById('MD_Preview');
      mdSplit?.addEventListener('click', () => null);
    }

    setDropdownButtonToolbar(rteObjRef.current, setTextSelection, params, field, setDisableLinkClass)
      .then((response) => {
        response.dropDownButtonIntern?.appendTo(`#dropdown-intern-${field.attribute.key}`);
        response.dropDownButtonExtern?.appendTo(`#dropdown-extern-${field.attribute.key}`);
      });
  };



  useEffect(() => {
    const Editable = ['create', 'edit'].includes(viewType);
    setDisableLinkClass(Editable);
  }, [viewType]);

  useEffect(() => {
    document.querySelectorAll('.e-rte-image').forEach((element) => {
      element.classList.remove('e-rte-image');
    });

    const Editable = ['create', 'edit'].includes(viewType);
    setDisableLinkClass(Editable);
  }, [viewType, value]);

  useEffect(() => {
    setDisableLinkClass(false);
  }, []);


  if (['create', 'edit'].includes(viewType)) {
    return (
      <FormInputContainerStyled hidden={+field.hidden}>
        <FormInputLabelContainerStyled>{t(field.label)}</FormInputLabelContainerStyled>
        <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
        <div style={{ width: '100%' }}>
          <RichTextEditorComponent
            id={`markdown-preview-${field.attribute.key}`}
            ref={rteObjRef}
            formatter={field.editorMode === 'markdown' ? new MarkdownFormatter({ listTags: { OL: '1., 2., 3.' } }) : null}
            height={field.height ?? 450}
            toolbarSettings={{ enable: true, ...getToolbarSetting(field) }}
            editorMode={field.type === 'markdown' ? 'Markdown' : 'HTML'}
            enableResize={true}
            showTooltip={false}
            value={value || ''}
            change={getChanges}
            created={onCreate}
            actionComplete={field.type === 'markdown' ? actionComplete : null}
            pasteCleanupSettings={pasteCleanupSettings}
          >
            <Inject services={[Toolbar, HtmlEditor, MarkdownEditor, Image, Link, Table, QuickToolbar, Resize, PasteCleanup]} />
          </RichTextEditorComponent>
        </div>
      </FormInputContainerStyled>
    );
  } else {
    return (
      <FormInputContainerStyled hidden={+field.hidden}>
        <FormInputLabelContainerStyled>{t(field.label)}</FormInputLabelContainerStyled>
        <div className='parsedHTML richtext-container' style={{ height: value !== null ? `${field.height}px` : '70px', width: '100%', overflowY: 'auto' }}>
          <div>
            {value ? (
              <RichTextEditorComponent
                readonly={true}
                ref={rteObjRef}
                value={field.type === 'markdown' ? marked(value) : value}
                editorMode={'HTML'}
                className={'richtext-view'}
              >
                <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
              </RichTextEditorComponent>
            ) : (
              ''
            )}
          </div>
        </div>
      </FormInputContainerStyled>
    );
  }
};

RichTextField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any,
  params: PropTypes.object,
  viewType: PropTypes.string,
  renderData: PropTypes.any
};

export default connect()(RichTextField);
